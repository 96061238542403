.auxcheck
{
    position: relative;
}
.dropdown
{
    background-color: #1F2945;
    border-radius: 22px;
}
.application
{
    font-size: 16px;
    margin-top: 5px;
    line-height: 24px;
    color: white;
    padding-top: .5rem;
    padding-left: 33px;
    padding-bottom: .5rem;
    padding-right: .5rem;
}

.dropdown-menu
{
    background-color: #1F2945;
    color: white;
}

.dropdown-menu a {
    color: white;
}
.buttonToggle
{
    background-color: #1F2945;
    color: white;
    border-radius: 22px;
}
.helpfaq
{
    font-size: 40px;
    line-height: 60px;
    font-family: 'Poppins', sans-serif;
    color: white;
    font-weight: 700;
    margin-left: 25px;
}
.question{
    color: white;
    size: 35px;
}

.searchFa{
    color: black;
    margin: 20px;
}

.makeCircle{
    border-radius: 50%;
    display: inline-block;
    background-color: white;
    position: absolute;
    bottom: 30px;
    right: 25px;
}

.veticalLine2 ul {
    padding-left: 15px;
}