
.help-content a {
   text-decoration: none;
   color: #1CC8EE;
}
.help-content a:hover{
   text-decoration: underline;
   font-size: 20px;
   transition: 0.5s all ease-in-out;
}

.help-content h1{
    font-family: 'Poppins', sans-serif;
    color: white;
    font-size: 60px;
    line-height: 80px;
    font-weight: 700  ;
 }
 .help{
    height: 60vh;
 }
 .help-content p{
    margin-top: 25px;
    color: white;
    font-size: 18px;
    line-height: 30px;
 }
 .remove2div{
   visibility: hidden;
   display: none;
 }

 .helpbutton
 {
    background : transparent ; 
    margin-top: 10px;
    margin-right:10px ;
    color:  white;
    border: none;
 }

 .helpbutton2
 {
    background : transparent ; 
    margin-top: 10px;
    margin-right:10px ;
    color:  #1CC8EE;
    border: none;
    border-bottom: 2px solid #1CC8EE;
 }

 .hrN{
    width :'100%' ;
    color : 'white' ;
    background-color: #ccc;
    margin: 0px;
    
 }


.box{
   margin-top: 50px;
   width: 900px;
   height: 50px;
   background-color: #47557A;
   border-radius: 50px;
   display: flex;
   align-items: center;
   padding: 20px;
}

.box > input {
   flex: 1;
   height: 40px;
   border: none;
   outline: none;
   font-size: 18px;
   padding-left: 10px;
   background-color: #47557A ;
   color: white;
}

.veticalLine {
   border-left: 2px solid #222B43;  
}

.divunderline li {
   text-decoration: underline;
   color: white; 
   margin-bottom: 10px;
   cursor: pointer;

}

.divunderline li{
   color : white;
   margin-bottom: 10px;
   text-decoration: underline;
   cursor: pointer;
}


.removediv .but123{
   display: flex  ;
   width: 90%;
   margin: 13px;
   border-radius: 25px;
   height: 44px;
   padding: 10px;
   background-color: #47557A  ;
}


.removediv .but124{
   display: flex;
   width: 90%;
   margin: 13px;
   border-radius: 25px;
   height: 44px;
   padding: 10px;
   background-color:rgb(28, 200, 238);
}

.removediv .but123:hover{
   background-color:rgb(28, 200, 238);
}

.but123 .butindiv{
   width: 100%;
   background: transparent;
   border-style: none;
   color:white ;
}
.but124{
   box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
}
.but123{
   box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
}
.but124 .butindiv{
   width: 100%;
   background: transparent;
   border-style: none;
   color:white ;
}
.but123 .butindiv:active{
   background-color:rgb(28, 200, 238); 
}

.row ul {
   
      -moz-column-count: 2;
    
      -webkit-column-count: 2;
     
      column-count: 2;
  

}


.faq_li {
   text-decoration: underline;
   color: white; 
   margin-bottom: 10px;
   cursor: pointer;

}
