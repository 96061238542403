.finaljobapply{
    background: #161D2F;
    padding-left: 20px;
    padding-right: 20px;

    /* margin: 20px; */

}

.finaljobapply h1{
    /* font-family: Luckiest Guy; */
    font-family: 'Poppins', sans-serif;
    font-size: 24px;
    line-height: 34px;
    color: white;
    /* margin: 20px; */
}

.finaljobapply h3 {
    font-family: 'Poppins', sans-serif;
    color: white;
    font-size: 26px;
    font-weight: 700;
    line-height: 20px;
    margin-top: 50px;
    margin-bottom: 20px;
}

.finaljobapply .description{
     font-family: 'Poppins', sans-serif;
    color: white;
    font-size: 18px;
    line-height: 20px;
    margin-top: 20px; 
}
.list-unstyled{
    font-family: 'Poppins', sans-serif;
    color: white;
    font-size: 18px;
    line-height: 26px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.list-unstyled ol{
    padding-left: 16px;
}
.title{
    font-family: 'Poppins', sans-serif;
    color: white;
    font-size: 22px;
    line-height: 26px;
    margin-top: 20px;
}

.btn-area{
    display: flex;
      justify-content: flex-end;
      /* font-family: Poppins; */
    font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
       line-height: 16px;
       letter-spacing: 1.25px;
      text-transform: uppercase;
      /* background: #1F2A45; */
      height: 50px;
}

.cancel-btn {
    margin-right: 1rem;
    padding: 18px 28px;
    background-color: #161d2f;
    border: 1px solid #485881;
    box-sizing: border-box;
    border-radius: 4px;
    color: #485881;
 }
 .apply-btn4 {
    margin-right: 1rem;
    padding: 18px 28px;
    background: #1cc8ee;
    border: 1px solid #485881;
    box-sizing: border-box;
    border-radius: 4px;
    color: white;
 }

 .cancel-btn:hover{
    transform: scale(1.05);
    transition: .6s;
 }
 .apply-btn4:hover{
    transform: scale(1.05);
    transition: .6s;
 }