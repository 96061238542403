

.helpdiv{
    position: relative;
    height: auto;
    min-height: 100% !important;
    min-height: 100% !important;
    background-color: #161D2F;
    padding-left: 20px;
    padding-right: 20px;
}

.combinecontent , .combinecontent2{
    display: flex;
    margin-top: 30px;
}
 .helpdiv .combinecontent h6 {
    color: yellow;
    margin-top: 12px;
}

.helpdiv .combinecontent2 p{
    margin-top: 0px;
    font-style: italic;
    font-size: 14px;
}
.thumbsUp{
    color: yellow;
    margin-top: 5px;
    margin-right: 8px;
}

.buttonhlp
{
    border-radius: 4px;
    background-color: transparent;
    margin-right: 10px;
    border-color: white;
    padding: 5px;
    padding-right: 20px;
    color:white ;
}
.buttonhlp:hover{
    border-color: #1CC8EE;
    color:#1CC8EE ;
}

.buttonhlp2
{
    border-radius: 4px;
    background-color: transparent;
    margin-right: 10px;
    border-color: #1CC8EE;
    padding: 5px;
    color:#1CC8EE ;
    padding-right: 20px;
}

.helpdiv h1{
    color: white;
    margin-top: 30px;
    margin-bottom: 40px;
    font-family: 'Poppins', sans-serif;
    line-height: 52px;
    font-weight: 400;
    font-size: 40px;
}

.helpdiv p{
    margin-top: 24px;
    color: white;
    font-size: 17px;
    line-height: 30px;
}

.helpdiv h6{
    margin-top: 25px;
    color: white;
    font-size: 16px;
    line-height: 0px;
}

.hrN1{
    width :'100%' ;
    color : 'white' ;
    background-color: #222B43; 
    height: 1.5px;
    margin: 8px;
 }

 .QuestionCircle{
     color: white;
     margin-top: 8px;
     margin-right: 10px;
 }

 .helpdiv h3{
     color: white; 
 }
.cancelCircle{
    position: relative;
    top: -25px;
    cursor: pointer;
}

 .helpdiv ul li{
    color: white;
    line-height: 38px;
    text-decoration: underline;
 }

 .combinecontent2 a:hover{
     font-size: 17px;
     transition: 0.5s all ease-in-out;
 }

 .MuiPaper-root {
    color: rgba(0, 0, 0, 0.87);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: #161d2f !important;
}