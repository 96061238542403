.Backdrop3{
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    background-color: rgba(0,0,0,0.9);
    overflow-y: scroll;
    overflow-x: hidden;
}
.joblength{
    height: 80vh;
}
.jobs{
    height: 60vh;
}

.list-unstyled{
    font-family: 'Poppins', sans-serif;
    color: white;
    font-size: 18px;
    line-height: 26px;
    margin-top: 20px;
    margin-bottom: 20px;
}



.jobs-content h1{
    color: white;
    /* font-family: Luckiest Guy; */
    /* font-family: 'Poppins, sans-serif' */
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    font-size: 64px;
    line-height: 80px;
    margin-bottom: 35px;
}
.jobs-content h6
{
    color: white;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
}
.jobs-content h3{
    font-size: 19px;
    font-weight: 400;
    line-height: 28px;
    font-family: 'Poppins', sans-serif;
    color: white;
    margin-top: 15px;
}

.job-sectors h1{
    font-size: 48px;
    font-weight: 700;
    line-height: 60px;
    font-family: 'Poppins', sans-serif;
    color: white;
    margin-top: 15px;
}
.card-body:hover{
    background:#1CC8EE;
    transition: 0.8s;
    cursor: pointer;
}
.card:hover{
    transform: scale(1.05);
    transition: 0.8s;
}