.applength{
    height: 20vh;
}


.musicApp
{
    height: 720px;
}
.musicApp2
{
    height: 650px;
}
.graphimg
{
    height: 250px;
}

.myApp-content h1{
    color: white;
    font-family: 'Poppins', sans-serif;
    line-height: 60px;
    font-weight: 700;
    font-size: 58px;
}

.myApp-content h3{
    color: white;
    font-family: 'Poppins', sans-serif;
    line-height: 27px;
    font-weight: 700;
    font-size: 18px;
    /* margin-bottom: 20px; */
}
.myApp-content p{
    color:  #919CB9;
    font-family: 'Poppins', sans-serif;
    line-height: 18px;
    font-weight: 600;
    font-size: 14px;
}

.appbuttonPlayStore{
    margin-top: 8px;
    height: 45px;
}
.appbuttonAppStore{
    height: 45px;
}

.myApp-content2 h1{
    color: white;
    font-family: 'Poppins', sans-serif;
    line-height: 80px;
    font-weight: 700;
    font-size: 44px;
}

.myApp-content2 h3{
    color: white;
    font-family: 'Poppins', sans-serif;
    line-height: 27px;
    font-weight: 700;
    font-size: 18px;
    /* margin-bottom: 20px; */
}
.myApp-content2 p{
    color:  #919CB9;
    font-family: 'Poppins', sans-serif;
    line-height: 21px;
    font-weight: 600;
    font-size: 14px;
}
.appbuttondiv{
    display: show;
    /* background-color: red; */
    background-color: rgba(18, 26, 50, 0.3);
    padding: 20px;
    position: absolute;
    top: 740px;
    left: 122px;
    width: 300px;
    height: 70px;
    border-radius: 50px;
}

@media (max-width: 1150px) {
    .appbuttondiv{
        left: 85px;
        width: 260px;
    }
}

@media (max-width: 850px) {
    .appbuttondiv{
        left: 25px;
        width: 240px;
    }
}
@media (max-width: 680px) {
    .appbuttondiv{
        visibility: hidden;
        display: none;
    }
}

/* @media (max-width: 420px) {
    .appbuttondiv{
        visibility:visible;
        display: show;
        top: 950px;
        left: 65px;
    }
} */
