.homelength{
    height: 70vh;
}

.home-content h1{
    color: white;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    font-size: 58px;
    line-height: 80px;
    /* margin-bottom: 20px; */
}
.buttondiv
{
    display: show;
    /* background-color: red; */
    background-color: rgba(18, 26, 50, 0.3);
    padding: 20px;
    position: absolute;
    top: 730px;
    left: 106px;
    width: 330px;
    height: 70px;
    border-radius: 50px;
}
.butt1{
    height: 35px;
    width: 80px;
    border: 1px solid #1CC8EE;
    background-color: transparent;
    color: #1CC8EE ;
    border-radius: 18px;
}
.butt2{
    height: 35px;
    width: 80px;
    background-color: transparent;
    border-radius: 18px;
    border: 1px solid white;
    color: #8D8D8D;

}
.butt12{
    height: 35px;
    width: 80px;
    border: 1px solid #1CC8EE;
    background-color: #2D3A3D;
    color: #1CC8EE ;
    border-radius: 18px;
}
.butt22{
    height: 35px;
    width: 80px;
    background-color: #2D3A3D;
    border-radius: 18px;
    border: 1px solid white;
    color: #8D8D8D;

}

.playstore{
    height: 720px;
    width: 350px;
}
.buttonPlayStore
{   width: 195px;
    height: 195px;
    margin-top: 10px;
}
.home-content h3{
    color: white;
    font-family: 'Poppins', sans-serif;
    line-height: 27px;
    font-weight: 700;
    font-size: 18px;
    /* margin-bottom: 20px; */
}

.home-content p{
    color:  #919CB9;
    font-family: 'Poppins', sans-serif;
    line-height: 21px;
    font-weight: 600;
    font-size: 14px;
}

.home-content .h12{
    color: #919CB9;
    font-family: 'Poppins', sans-serif;
    line-height: 80px;
    font-weight: 700;
    font-size: 48px;
}





.buttonpolicy2 {
    display: inline-block;
    border-style: none;
    box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
    position: fixed;
    bottom: 25px;
    right: 25px;
    padding : 10px;
    margin: 2px;
    background :#1F2A45;
    color :white;
    border: none;
    box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
  }
  
  .buttonpolicy2:hover {
      background-color: #1F2A45;

    }
  
  .buttonpolicy2:active {
    background-color: #1F2A45;
    box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
    transform: translateY(4px);
  }

/* .buttonpolicy2:hover {
    border-style: none;
    box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
    position: relative;
    padding : 10px;
    margin: 2px;
    background :#1F2A45;
    color :white;
} */





@media (max-width: 1150px) {
.buttondiv
{
    /* background-color: red; */
    left: 38px;
    width: 300px;
}

}
@media (max-width: 830px) {
    .buttondiv
    {
        left: 18px;
        width: 260px;
    }
    .buttonpolicy2
    {
        display: none;
        visibility: hidden;
    }

}

@media (max-width: 830px) {
    .buttondiv
    {
        left: 18px;
        width: 260px;
    }


}
@media (max-width: 725px) {
    .buttondiv
    {
        display: none;
        visibility: hidden;
    }
}
/* @media (max-width: 414px) {
    .buttondiv
    {
        position: absolute;
        display: show;
        visibility: visible;
       
    }
}

@media (max-width: 370px) {
    .buttondiv
    {
        display: show;
        visibility: visible;
        top: 930px;
        left: 60px;
    }
}
@media (max-width: 320px) {
    .buttondiv
    {
        display: show;
        visibility: visible;
        top: 930px;
        left: 40px;
    }
} */