.successh1{
    position: relative;
    background: #161D2F;
    height: 100vh;
    color: white;
    padding: 15px;
}
.successh1 img{
    margin-bottom: 25px;
}
.successh1 h1{
    font-weight:900;
    font-size: 36px;
    font-family: Luckiest Guy;
    /* font-family: 'Poppins', sans-serif; */
    line-height: 51px;
    font-style: normal;
}

.success h6 {
    font-size: 12px;
    font-weight: 400;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    line-height: 20px;
}
.check{
    top: 120px;   
}