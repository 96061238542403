.mobileSdiv
{
   height: inherit;
    background-color: #1F2945;
    overflow-y:scroll;
}

.ImCancelCircle
{
    margin: 10px;
    color: white;
}

.mobileSh6
{
    margin-top: 15px;
    color: white;
    font-size: 28px;
}

.boxnice{
    margin: auto;
    width: 350px;
    height: 50px;
    background-color: #47557A;
    border-radius: 50px;
    display: flex;
    align-items: center;
    padding: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
 }
 
 .boxnice > input {
    flex: 1;
    height: 40px;
    border: none;
    outline: none;
    font-size: 18px;
    padding-left: 10px;
    background-color: #47557A ;
    color: white;
 }
 
 .mobileh5{
     color: white;
     padding: 7px;
 }

 .mobileh52{
    color: white;
    margin-top: 20px;
    padding: 7px;
}

 .mobileul{
     color: white;
 }
 .mobileul li{
     color: white;
 }