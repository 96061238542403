.uploadLabel{
  margin-top: 20px;
}


/* .uploadLabel{
  margin-top: 20px;
  border:4px solid rgb(255, 255, 255);
} */

.container{  
    font-family:sans-serif;
    margin: auto; 
    width: 350px; height: 140px;
    background-color: #161D2F;
    border-radius: 10px;
    border:1px solid rgb(255, 255, 255);
    /* / background-color: #9ab7ff; /
    / border:4px solid black / */
  }

  
.container:hover {  
    
    border-radius: 10px;
    cursor: auto !important;
    border:1.4px solid  #1cc8ee
}
  
 
  
  legend{
   width: 30%;
  font-size: 12px;
  position:static;
  /* margin-left: calc(10% - 9px - 10px); */
  color: #1cc8ee;
  background-color: #161D2F;
  padding-bottom: 20px;   
  }

 
  
  .innerElement{
      display: flex;
      flex-direction:column;
      align-items: center;
      color: white;
  }
  .innerElement .link{
    color: aqua;
  }
  
  input{ border: 1px solid; }
  td:first-child{ width: 100px; }


  .clickHereLable{
    cursor: pointer !important;
  }

  .link{
    margin-bottom: 0px;
  }