
.job-apply2{
    background: #161D2F;
    height: 100vh ;
    overflow-x: hidden;
    /* overflow-y: hidden; */
    padding-left: 20px;
}


/* input[type='file'] {
display: block;
color: #1CC8EE;
} */

.ml-2{
    /* font-family: Luckiest Guy; */
    font-family: 'Poppins', sans-serif;
    font-size: 24px;
    line-height: 34px;
    color: white;
}

.btn-area2{
    display: flex;
      justify-content: flex-end;
      font-family: Poppins;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
       line-height: 16px;
       letter-spacing: 1.25px;
      text-transform: uppercase;
      margin-top: 160px;
       /* background: #1F2A45;  */
      height: 50px;
}

.cancel-btn2 {
    margin-right: 1rem;
    padding: 18px 28px;
    background-color: #161d2f;
    border: 1px solid #485881;
    box-sizing: border-box;
    border-radius: 4px;
    color: #485881;
 }
 .apply-btn42 {
    margin-right: 1rem;
    padding: 18px 28px;
    background: #1cc8ee;
    border: 1px solid #485881;
    box-sizing: border-box;
    border-radius: 4px;
    color: white;
 }

 .cancel-btn2:hover{
    transform: scale(1.05);
    transition: .6s;
 }
 .apply-btn42:hover{
    transform: scale(1.05);
    transition: .6s;
 }

 .drop-zone-area {
   height: 50%;
}

.changewidth{
   width: 350px;
   
}
@media (max-width: 420px)
{
   
.changewidth{
   width: 350px;
}
}
