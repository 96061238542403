.Backdrop {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.9);
    overflow-y: scroll;
    overflow-x: hidden;
}
.aboutus{
    height :70vh;
}
.aboutlength
{
    height: 80vh;
}
.about-content h1 {
    color: white;
    /* font-family: Luckiest Guy; */
   font-family: 'Poppins', sans-serif;
   font-weight: 700;
   font-size: 64px;
   line-height: 80px;
}

.about-content h6 {
    color: white;
    font-size: 18px;
    line-height: 30px;
    font-family: 'Poppins';
}

.about2div h1 {
    color: white;
    font-size: 48px;
    /* font-family: Luckiest Guy; */
   font-family: 'Poppins', sans-serif;
    line-height: 48px;
    font-weight: 400;
}

.about2div .team h3 {
    color: white;
    font-size: 24px;
    /* font-family: Luckiest Guy; */
   font-family: 'Poppins', sans-serif;
    line-height: 24px;
    font-weight: 400;
    margin-top: 10px;
}

@media (max-width: 877px) {
    .about-content {
        margin-top: 55px;
    }
}

@media (max-width: 580px) {

    .largetdiv .remove2div{
        visibility:visible;
        display:block;
    }
    .helpdiv{
        height:260vh;
    }
    .removediv{
        visibility: hidden;
        display: none;
    }
    /* here is the responsive code for the contact us page  */

    .masterdiv232{
        visibility: hidden;
        display: none;
    }
    .buttonpolicy {
        padding : 10px;
        background :#1F2A45;
        width: 180px;
        color :white;
    }
    .about-content2 .policyh4{
        margin-top: 5px;
    }
    .Backdrop1 {
        overflow-y: scroll;
    }
    .about-content {
        margin: 55px;
    }
    
    .jobs-content {
        margin: 35px;
        text-align: center;
    }
    .team .Ashish {
        margin-top: 15px;
        margin-bottom: 15px;
    }
    .team .Sudhanshu {
        margin-top: 15px;
        margin-bottom: 25px;
    }
    .team .Himanshu {
        margin-top: 15px;
        margin-bottom: 25px;
    }
    .advertise-content {
        margin: 35px;
        text-align: center;
    }
    .top2 {
        position: relative;
        top: 220px;
    }
    /* all the responsive code of advertise with us is here   */
    .advertise-content .top {
        position: relative;
        top: -700px;
    }
    .advertise-content .top h1 {
        color: #FFFFFF;
        font-family: 'Poppins', sans-serif;
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
    }
    .advertise-content .top p {
        margin-top: 15px;
        color: #919CB9;
        font-family: 'Poppins', sans-serif;
        font-weight: 500;
        font-size: 10px;
        /* line-height: 21px; */
    }
    .advertise-content .top h6 {
        color: #FFFFFF;
        font-family: 'Poppins', sans-serif;
        font-size: 16px;
        line-height: 24px;
    }
    /* all the responsive code of contact is here   */
    .about-content2 {
        /* margin: 35px; */
        padding: 0px;
        text-align: center;
        /* overflow-y: scroll; */
        /* overflow-x: ; */
    }
    .contact{
        width: 240px;
    }
    .about-content2 h1 {
        font-family: 'Poppins', sans-serif;
        line-height: 34px;
        font-size: 24px;
    }
    .locate {
        width: 240px;
    }
    .about-content2 h6 {
        font-size: 12px;
        line-height: 23px;
        font-family: 'Poppins', sans-serif;
    }
    .about-content2 h5 {
        font-size: 16px;
        line-height: 20px;
        font-family: 'Poppins', sans-serif;
    }
    .about-content2 h4 {
        font-size: 16px;
        line-height: 14px;
        font-family: 'Poppins', sans-serif;
    } 

    /* all the code of responsive home page is here  */

    .playstore{
        height: 520px;
    }

    .home-content{
        text-align: center;
    }
    .home-content h1{
        line-height: 44px;
        font-size: 38px;
    }
    .home-content h3{    
        line-height: 21px;
        font-size: 14px;
        margin-top: 28px;
    }
    .home-content p{
        line-height: 18px;
        font-size: 13px;
        margin-bottom: 28px;
    }
    .home-content .h12{
        color: white;
        line-height: 32px;
        font-size: 24px;
    }

    .home-content {
        position: relative;
        top: -590px;
    }

    .playstore{
        position: relative;
        top: 435px;
        width: 300px;
        padding: 0 !important;
        margin: 0 !important;
    }

    .buttonPlayStore{
        margin-top: 5px;
        height : 170px;
        width: 150px;
    }
    .buttonAppStore{
        width: 150px;
    }


    /* all the code of responsive Napp page is here  */

    .myApp-content{
        text-align: center;
    }
    .myApp-content .counter1
    {
        margin-left: 25px;
    }
    .myApp-content .graphimg
    {
        visibility: hidden;
        display: none;
    }
    .myApp-content{
        position: absolute;
        top: -75px;
    }
    .musicApp
    {
        position: absolute;
        top: 485px;
    }
    .myApp-content h1{
        line-height: 40px;
        font-weight: 700;
        font-size: 44px;
    }
    .musicApp2
    {
        position: absolute;
        top: 530px;
    }
    .myApp-content .h12{
        margin-top: 40px;
        line-height: 30px;
        font-weight: 700;
        font-size: 24px;
    }
    .myApp-content .h1234{
        line-height: 60px;
        font-weight: 700;
        font-size: 44px;
    }


    /* help and faq content ka responsive content sara yaha ha  */

    .help-content{
        text-align: center;
    }



    /* responsive design for helpdrawer  */

    
}

