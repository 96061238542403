.contactlength{
    height: 80vh;
}

.buttonpolicy{
    border-style: none;
    box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
    position: relative;
    padding : 10px;
    margin: 2px;
    background :#1F2A45;
    color :white;
}
.buttonpolicy {
    display: inline-block;
    border-style: none;
    box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
    position: relative;
    padding : 10px;
    margin: 2px;
    background :#1F2A45;
    color :white;
    border: none;
    box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
  }
  
  .buttonpolicy:hover {
      background-color: #1F2A45;

    }
  
  .buttonpolicy:active {
    background-color: #1F2A45;
    box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
    transform: translateY(4px);
  }

.buttonpolicy:hover {
    border-style: none;
    box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
    position: relative;
    padding : 10px;
    margin: 2px;
    background :#1F2A45;
    color :white;
}


.masterdiv{
    height: 78vh;
    overflow-y: hidden;
}
.policyh4
{
    margin-top: 35px;
}
.newcontent{
    overflow-y: scroll;
    /* padding-right: 100px; */
}
/* ::-webkit-scrollbar {
    width: 0px; /* remove scrollbar space /
    background: transparent; / optional: just make scrollbar invisible /
    }
    / optional: show position indicator in red */
    /* ::-webkit-scrollbar-thumb {
    background: #FF0000;
    } */
/* } */ */

.locate22{
    height: 180px;
}

.about-content2 .hi2 a {
    
    text-decoration: none;
    color: #1CC8EE; 
}
.about-content2 .hi2 a:hover{
    text-decoration: underline;
    font-size: 17px;
    transition: 0.5s all ease-in-out;
}





.newcontent2 h3 {
    font-size: 20px;
    font-weight: 400;
    line-height: 14px; 
    /* font-family: Luckiest Guy; */
    /* font-family: 'Poppins', sans-serif; */
    font-family: 'Poppins', sans-serif;
    color: #B0B1B6;
    margin-top: 35px;
}
.newcontent2 p {
    font-size: 12px;
    font-weight: 400;
    line-height: 25px;
    /* font-family: Luckiest Guy; */
    /* font-family: 'Poppins', sans-serif; */
    color: #B0B1B6;
    margin-top: 20px;
}



.newcontent h1{
    color: white;
    /* font-family: Luckiest Guy; */
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    font-size: 64px;
    line-height: 80px;
    margin-bottom: 70px;
}

.newcontent h4 {
    font-size: 18px;
    font-weight: 400;
    line-height: 14px;
    /* font-family: Luckiest Guy; */
    /* font-family: 'Poppins', sans-serif; */
    font-family: 'Poppins', sans-serif;
    color: white;
    margin-top: 35px;
}

.newcontent p {
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    /* font-family: Luckiest Guy; */
    /* font-family: 'Poppins', sans-serif; */
    color: #B0B1B6;
    margin-top: 20px;
}

.about-content2 h1 {
    color: white;
    /* font-family: Luckiest Guy; */
    font-family: 'Poppins', sans-serif;
    line-height: 48px;
    font-weight: 700;
    font-size: 48px;
    margin-bottom: 20px;
}

.about-content2 h6 {
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    /* font-family: 'Poppins', sans-serif; */
    color: white;
    margin-top: 15px;
}

.about-content2 h5 {
    font-size: 18px;
    font-weight: 700;
    line-height: 20px;
    font-family: 'Poppins', sans-serif;
    color: white;
    margin-top: 35px;
}

.about-content2 h4 {
    font-size: 18px;
    font-weight: 400;
    line-height: 14px;
    /* font-family: Luckiest Guy; */
    /* font-family: 'Poppins', sans-serif; */
    color: white;
    margin-top: 35px;
}

@media (max-width: 320px) {
    .about-content2 h1 {
        color: white;
        /* font-family: Luckiest Guy; */
    font-family: 'Poppins', sans-serif;
        line-height: 48px;
        font-weight: 400;
        font-size: 40px;
        margin-bottom: 20px;
    }
    .locate {
        width: 230px;
    }
    .about-content2 .hi {
        font-size: 15px;
    }
    .home-content .buttonPlayStore{
        margin-top: 5px;
        height : 155px;
        width: 140px;
    }
    .home-content .buttonAppStore{
        width: 140px;
    }

    
}