.newcontent a {
    text-decoration: none;
    color: #1CC8EE;
 }
 .newcontent a:hover{
    text-decoration: underline;
    font-size: 20px;
    transition: 0.5s all ease-in-out;
 }

 @media ( max-width : 600px) { 

   .martc20{
      margin-left: 20px;
   }
 }
 